<template lang="pug">
  sidebar-menu(
    :tooltipText="title"
    :class="{'sidebar__menu_active': $route.name === nameToGo, 'sidebar__menu_sidebar': sidebarActive ,'mobile': mobile}"
    component="div"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
  )
    template(v-slot:icon)
      ico-list.sidebar-menu__icon

    .sidebar__sub(v-if="sidebarActive" @click="$emit('click:item')" @mouseover="$emit('mouseover')" @mouseleave="$emit('mouseleave')")
      sidebar-item(
        v-for="location in courseLocationsWithAdditionalInfo" :key="location.ID"
        :to="{name: nameToGo, query: { city: location.ID, page: 1 }}"
        :class="{'sub-item_active': isLocationActive(location.ID)}"
        :text="location.name"
      )
        template(v-slot:aside)
          span.sub-item__count {{ location.top_cbr_reserve_queue_count }}|{{ location.top_cbr_exchange_queue_count }}
</template>

<script>
import { mapGetters } from "vuex"
import SidebarItem from "@/components/global/sidebar/SidebarItem.vue"
import IcoList from "@/assets/img/ui/crm/IcoList.vue"
import IcoCourse from "@/assets/img/ui/crm/IcoCourse.vue"
import SidebarMenu from "@/components/global/sidebar/SidebarMenu.vue"

export default {
  props: {
    nameToGo: {
      type: String
    },
    title: {
      type: String,
      default: 'Q management'
    },
    sidebarActive: Boolean,
    mobile: Boolean
  },
  computed: {
    ...mapGetters({
      courseLocationsWithAdditionalInfo: 'crmCourseCities/itemsWithAdditionalInfo'
    }),
    selectedPage() {
      return this.$route.name === this.nameToGo
    }
  },
  methods: {
    async go(location) {
      await this.$router.push({name: this.nameToGo, query: {city: location, page: 1}});
      this.updateItems();
    },
    updateItems() {
      this.$store.dispatch('crmCourseCities/listWithAdditionalInfo')
    },
    isLocationActive(ID) {
      if (!this.selectedPage) return false
      if (!this.$route.query.city) return false
      if (this.$route.query.city == ID) return true
      return false
    }
  },

  components: {
    SidebarItem,
    IcoList,
    IcoCourse,
    SidebarMenu
  }
}
</script>

<style lang="scss" scoped>
.flex {
  span:first-of-type {
    margin-right: 5px;
  }
}

.mobile {
  .sidebar__sub {
    top: -90vh;
    left: 0;
  }
}

</style>
